import React from 'react';

import { isUndefined } from 'lodash';

function supportsIntersectionObserver() {
    return (
        !isUndefined(window) &&
        'IntersectionObserver' in window &&
        'IntersectionObserverEntry' in window &&
        'intersectionRatio' in IntersectionObserverEntry.prototype
    );
}

class IntersectionObserverPolyfill extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.initialize();
    }

    initialize() {
        if (supportsIntersectionObserver()) {
            this.setState({ ready: true });
        } else {
            // load the polyfill
            import('intersection-observer')
                .then(() => {
                    this.setState({ ready: true });
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.error('Error loading intersection-observer polyfill:', error);
                });
        }
    }

    render() {
        const { component: Component = 'div', ...restProps } = this.props;

        const { ready } = this.state;
        if (ready) {
            return (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Component {...restProps} />
            );
        }

        return null;
    }
}

export default IntersectionObserverPolyfill;
