import {
    ComponentType,
    FC,
    ReactNode,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';

import { THEMES } from 'types';

import AppContext, { AppState } from './AppContext';

export const TYPES = {
    NEWSLETTER: 'NEWSLETTER',
    WAITLIST: 'WAITLIST',
};

type Props = {
    children: ReactNode;
};

const AppProvider: FC<Props> = ({ children }) => {
    const initialState: AppState = {
        navigationOpen: false,
        popupOpen: false,
        popupId: null,
        popupData: null,
        pageTheme: THEMES.DEFAULT,
        openNavigation: () => {},
        closeNavigation: () => {},
        toggleNavigation: () => {},
        setPopupData: () => {},
        setPageTheme: () => {},
        togglePopup: () => {},
    };

    const [state, setState] = useState(initialState);
    const { popupOpen, popupData } = state;

    useEffect(() => {
        function onKeyUp(e) {
            if (e.key === 'Escape') {
                setState({
                    ...state,
                    navigationOpen: false,
                    popupOpen: false,
                    popupId: null,
                    popupData: null,
                });
            }
        }
        window.addEventListener('keyup', onKeyUp);
        return () => window.removeEventListener('keyup', onKeyUp);
    });

    useLayoutEffect(() => {
        const originalStyle = window.getComputedStyle(document.body).overflow;
        if (popupOpen || popupData) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = originalStyle;
        }

        return () => {
            document.body.style.overflow = originalStyle;
        };
    }, [popupOpen, popupData]);

    const openNavigation = useCallback(
        () =>
            setState((prevState) => ({
                ...prevState,
                popupOpen: false,
                popupId: null,
                navigationOpen: true,
            })),
        [],
    );

    const closeNavigation = useCallback(
        () =>
            setState((prevState) => ({
                ...prevState,
                navigationOpen: false,
            })),
        [],
    );

    const toggleNavigation = useCallback(
        () =>
            setState((prevState) => ({
                ...prevState,
                navigationOpen: !prevState.navigationOpen,
            })),
        [],
    );

    const setPopupData = useCallback(
        (popupData: Record<string, unknown> | null) =>
            setState((prevState) => ({
                ...prevState,
                popupData,
            })),
        [],
    );

    const setPageTheme = useCallback(
        (pageTheme: THEMES) =>
            setState((prevState) => ({
                ...prevState,
                pageTheme,
            })),
        [],
    );

    const togglePopup = useCallback(
        (id) =>
            setState((prevState) => ({
                ...prevState,
                popupOpen: !prevState.popupOpen,
                popupId: id,
            })),
        [],
    );

    const contextValue = useMemo(
        () => ({
            ...state,
            openNavigation,
            closeNavigation,
            toggleNavigation,
            setPopupData,
            setPageTheme,
            togglePopup,
        }),
        [
            state,
            openNavigation,
            closeNavigation,
            toggleNavigation,
            setPopupData,
            setPageTheme,
            togglePopup,
        ],
    );

    return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

export default AppProvider;

export function withAppContext<P extends object>(
    Component: ComponentType<P & { appContext: AppState }>,
): FC<P> {
    return (props) => (
        <AppContext.Consumer>
            {(context) => {
                if (!context) {
                    throw new Error(
                        'AppContext is undefined. Ensure AppProvider is wrapping the component tree.',
                    );
                }
                return <Component {...props} appContext={context} />;
            }}
        </AppContext.Consumer>
    );
}
