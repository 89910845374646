import { IGatsbyImageData } from 'gatsby-plugin-image';
import {
    ContentfulRichTextGatsbyReference,
    RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { DateTime } from 'luxon';

export enum DESIGN_VERSION {
    V1 = 'v1',
    V2 = 'v2',
    V3 = 'v3',
}

export enum ACTIONS {
    CALL = 'call',
    OPEN_SIGNUP = 'open signup popup',
    OPEN_WAITLIST = 'open waitlist popup',
    OPEN_YOUTUBE = 'open youtube popup',
}

export enum CTA_ACTIONS {
    JOIN_THE_WAITLIST = 'join the waitlist (open waitlist)',
    GET_NOTIFIED = 'get notified (open newsletter)',
    EXTERNAL_LINK = 'external link',
}

export enum THEMES {
    DEFAULT = 'default',
    WHITE = 'white',
    BEIGE = 'beige',
    BLACK = 'black',
    GREEN = 'green',
    ORANGE = 'orange',
    BLUE = 'blue',
    GREY1 = 'grey1',
    LIGHTGREY = 'lightGrey',
    MAINCOLOR = 'mainColor',
    TEXTCOLOR = 'textColor',
    TRANSPARENT = 'transparent',
}

export enum BUTTON_THEMES {
    DEFAULT = 'default',
    BLACK = 'black',
    WHITE = 'white',
    PERIWINKLE = 'periwinkle',
    GREY = 'grey',
    LIGHT_PERIWINKLE_NO_HOVER = 'lightPeriwinkleNoHover',
    BLACK_TRANSPARENT = 'blackTransparent',
    WHITE_TRANSPARENT_BLUE_BACKGROUND = 'whiteTransparentBlueBackground',
    WHITE_TRANSPARENT_GREEN_BACKGROUND = 'whiteTransparentGreenBackground',
    BLUE_TRANSPARENT = 'blueTransparent',
    CURRENT_COLOR_TRANSPARENT = 'currentColorTransparent',
    BLACK_TEXT_TO_BLACK_HOVER = 'blackTextToBlackHover',
    GREY_WITHOUT_BACKGROUND = 'greyWithoutBackground',
}

export type RichTextType = RenderRichTextData<ContentfulRichTextGatsbyReference>;

export type Coordinates = {
    lat: number;
    lon: number;
};

export type LinkToPage = {
    id?: string;
    subdomain?: string;
    slug: string;
};

type FileType = {
    url: string;
    contentType: string;
    details?: {
        image?: {
            width: number;
            height: number;
        };
    };
};

export type ContentfulAsset = {
    id: string;
    __typename?: string;
    svg?: string | null;
    title?: string;
    description?: string;
    file: FileType;
    gatsbyImageData?: IGatsbyImageData & { sizes?: string };
};

export type CTA = {
    cta: CTA_ACTIONS;
    ctaExternalLinkText: string;
    ctaBookingText: string;
    ctaSubtext: string;
    ctaExternalLinkUrl: string;
    promoText: string | null;
};

export type ContentfulLocation = {
    __typename: string;
    id: string;
    displayName: string;
    clinicName: string;
    internalName: string;
    cta: CTA;
    emailListSourceKey: string;
    mainImage: ContentfulAsset;
    address: string;
    address2: string | null;
    city: string;
    state: string;
    zipCode: string;
    coordinates: Coordinates;
    slug: string;
    status: string;
    openStatusColor: string;
    openStatusText: string;
    comingSoonStatusText: string;
    text: {
        raw: string;
    };
    sections: Record<string, unknown>[];
};

export type ContentfulRegion = {
    __typename: string;
    id: string;
    slug: string;
    displayName: string;
    regionName: string;
    waitlistShortName: string | null;
    statusText: string;
    city: string;
    coordinates: Coordinates;
    emailListSourceKey: string | null;
    locations: ContentfulLocation[];
};

export type PressItemType = {
    id: string;
    internalName: string;
    publicationLogo: ContentfulAsset;
    publicationTitle?: string;
    articleTitle?: string;
    datePublished: DateTime;
    url: string;
};

export type ContentListType = {
    id: string;
    internalName: string;
    theme: THEMES;
    designSystem: DESIGN_VERSION;
    headline: RenderRichTextData<ContentfulRichTextGatsbyReference>;
    action: ContentfulLink | ContentfulButton;
    hideActionOnDesktop?: boolean;
    width: string;
    items: PressItemType[];
};

export type TeamMemberType = {
    id: string;
    internalName: string;
    media: ContentfulAsset;
    name: string;
    role: string;
    location: string;
    education: string;
    areaOfExpertise: string;
    areasOfInterest: string;
    biography: RenderRichTextData<ContentfulRichTextGatsbyReference>;
    sections: ContentListType[];
};

export type TickerTextType = {
    id: string;
    internalName?: string;
    eyebrow?: string;
    tickingText?: RichTextType;
    actions?: (ContentfulLink | ContentfulButton)[];
};

export type NavigationLinkType = {
    id: string;
    internalName?: string;
    label: string;
    to: {
        linkToPage: {
            id: string;
            subdomain?: string;
            slug: string;
        };
    };
    toExternalUrl?: string;
    action?: string;
};

export type NavigationMenuType = {
    id: string;
    internalName?: string;
    heading?: string;
    navigationLinks: NavigationLinkType[];
};

export type DropdownLinksType = {
    id: string;
    label: string;
    toExternalUrl?: string;
    to?: { id: string; subdomain?: string; slug: string };
};

export type ContentfulLink = {
    __typename: 'ContentfulLink';
    id: string;
    label: string;
    linkToPage: { id: string; subdomain?: string; slug: string } | null;
    to: string;
    action: ACTIONS | null;
    openInNewTab: boolean;
};

export type ContentfulButton = ContentfulLink & {
    designSystem?: DESIGN_VERSION;
    theme?: 'primary' | 'secondary' | 'tertiary';
    arrowIcon?: boolean;
    youtubeId?: string;
};

export type ContentfulSeo = {
    __typename: 'ContentfulSeo';
    id: string;
    keywords: string[];
    description: {
        description: string;
    };
    shareImage: {
        file: {
            url: string;
        };
    };
};

export type ContentfulNavigationLink = {
    __typename: 'ContentfulNavigationLink';
    id: string;
    label: string;
    theme: string;
    toExternalUrl: string | null;
    action: string | null;
    to: { id: string; subdomain?: string; slug: string };
    image: {
        id: string;
        gatsbyImageData: IGatsbyImageData;
    } | null;
    dropdownLinks: DropdownLinksType[] | null;
};

export type ContentfulNavigationMenu = {
    __typename: 'ContentfulNavigationMenu';
    id: string;
    heading: string | null;
    navigationLinks: ContentfulNavigationLink[];
};

export type ContentfulSiteSettings = {
    __typename: 'ContentfulSiteSettings';
    id: string;
    title: string;
    copyright: string;
    companyAddress: string;
    companyEmail: string;
    companyPhoneNumber: string | null;
    privacyUrl: string;
    termsUrl: string;
    favicon: IGatsbyImageData;
    touchIcon: IGatsbyImageData;
    defaultSeo: ContentfulSeo;
    bannerText?: RichTextType;
    bannerColor: string | null;
    headerCta: ContentfulLink;
    headerSecondaryCta: ContentfulLink;
    footerCta: {
        __typename: 'ContentfulTickerTextSection';
        id: string;
        theme: string;
        eyebrow: string;
        tickingText: RichTextType;
        actions: (ContentfulLink | ContentfulButton)[];
    };
    headerNavigation: ContentfulNavigationMenu;
    navigationImage: ContentfulAsset;
    primaryNavigation: ContentfulNavigationMenu;
    mobileNavigation: ContentfulNavigationMenu;
    mobileNavigationBottomButtons: ContentfulLink[];
    secondaryNavigation: ContentfulNavigationMenu;
    footerNavigationColumns: ContentfulNavigationMenu;
    emailPopupText: RichTextType;
    waitlistMarketLabel: string;
    waitlistClinicLabel: string;
    waitlistContactText: string;
    contactTextComingSoon: string;
    downloadTheAppImage: ContentfulAsset;
    downloadTheAppEyebrow: string;
    downloadTheAppDisplayHeader: string;
    downloadTheAppParagraph: RichTextType;
    downloadTheAppDisclaimer: string;
    downloadTheAppMethod: string;
    downloadTheAppQrCode: ContentfulAsset;
};
